<template>
    <div class="p-default">
        <div class="max-w-full overflow-hidden flex flex-col lg:flex-row items-start lg:items-center justify-between space-x-0 lg:space-x-default space-y-default lg:space-y-0">
            <div class="w-full">
                <template v-if="hasDefaultSlot">
                    <h1 class="text-lg font-medium text-gray-100">
                        <i class="fa fa-angle-double-right text-secondary-500" />
                        <slot />
                    </h1>
                </template>

                <template v-if="hasDescriptionSlot">
                    <p class="mt-1 text-sm text-gray-400 truncate break-words whitespace-normal">
                        <slot name="description" />
                    </p>
                </template>
            </div>

            <template v-if="hasActionsSlot">
                <slot name="actions" />
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: "LayoutsPanelSectionHeading",
    computed: {
        hasDefaultSlot() {
            return !!this.$slots.default;
        },
        hasDescriptionSlot() {
            return !!this.$slots.description;
        },
        hasActionsSlot() {
            return !!this.$slots.actions;
        },
    },
};
</script>
